/* Themes */
.theme--light.v-sheet {
  background-color: #fff;
  border-color: #fff;
  color: rgba(0,0,0,0.87);
}
.theme--dark.v-sheet {
  background-color: #424242;
  border-color: #424242;
  color: #fff;
}
.v-sheet {
  display: block;
  border-radius: 2px;
  position: relative;
}
.v-sheet--tile {
  border-radius: 0;
}
